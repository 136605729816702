import { defineStore } from 'pinia'
import type { Vehicle } from '~/utils/types/inventory'
import type { Location } from '~/utils/types/location'
import type { LeadModalOpenParams } from '~/utils/types/leadModal'
import { useFetchDealerMe } from '~/composables/fetch/useFetchDealerMe'
import type { FetchResponse } from '~/utils/types/global'
import type {
    LeadButtonData,
    CTAButtonComponentType,
} from '~/utils/vdpLeadComponentSchema'

export const useLeadModalStore = defineStore('leadModal', {
    state: (): State => ({
        open: false,
        button: null,
        vehicle: null,
        location: null,
        serverErrors: null,
        referer: null,
    }),
    actions: {
        openModal(
            referer: CTAButtonComponentType,
            button: LeadButtonData,
            data: LeadModalOpenParams = {},
        ): void {
            const { vehicle, location } = data

            this.referer = referer
            this.button = button
            this.vehicle = vehicle ?? null
            this.location = location ?? null

            this.open = true
        },

        closeModal(): void {
            this.open = false

            // the timeout lets the component hold its space until it transitions out
            setTimeout(() => {
                this.referer = null
                this.button = null
                this.vehicle = null
                this.location = null

                this.clearServerErrors()
            }, 250)
        },

        clearServerErrors(): void {
            this.serverErrors = null
        },

        async submitLead(body: Record<string, any>): Promise<FetchResponse> {
            return await useFetchDealerMe('/leads', {
                method: 'post',
                body,
                fetch: true,
            })
        },
    },
})

interface State {
    open: boolean
    button: LeadButtonData | null
    vehicle: Vehicle | null
    location: Location | null
    serverErrors: Record<string, string[]> | null
    referer: CTAButtonComponentType | null
}
